import { Button, Carousel, Col, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import CheckboxList from "@/ui/_common_/checkbox-list";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./form.state";

type Props = {
  state: FormState;
};

export const EditFormView: FC<Props> = observer(({ state }) => {
  const { intl, isLoading, isRejected, carouselRef, dir } = state;

  if (isLoading) {
    return <FormSpinner />;
  }

  if (isRejected) {
    return (
      <ErrorsFormatter
        queries={[state.providersQuery, state.brandsQuery, state.gamesQuery]}
      />
    );
  }

  const items = [
    <div key="providers" className="providers">
      <CheckboxList
        title={intl.formatMessage({ defaultMessage: "Providers" })}
        items={state.providerCheckboxList}
        onSelect={state.handleProviderSelect}
        onCheck={state.handleProviderCheck}
        onCheckAll={state.handleProviderCheckAll}
      />
    </div>,
    <div key="brands" className="brands">
      <CheckboxList
        title={intl.formatMessage({ defaultMessage: "Brands" })}
        items={state.brandCheckboxList}
        onSelect={state.handleBrandSelect}
        onCheck={state.handleBrandCheck}
        onCheckAll={state.handleBrandCheckAll}
      />
    </div>,
    <div key="games" className="games">
      <CheckboxList
        title={intl.formatMessage({ defaultMessage: "Games" })}
        isSelectable={false}
        items={state.gameCheckboxList}
        onCheck={state.handleGameCheck}
        onCheckAll={state.handleGameCheckAll}
      />
    </div>,
  ];

  const orderedItems = dir === "rtl" ? [...items].reverse() : items;

  return (
    <Space direction="vertical">
      <ErrorsFormatter
        queries={[state.mutation, state.setToChildrenMutation]}
      />
      <Carousel
        ref={carouselRef}
        infinite={false}
        slidesToShow={3}
        responsive={[{ breakpoint: 480, settings: { slidesToShow: 1 } }]}
        className="checkbox-list-carousel"
      >
        {orderedItems}
      </Carousel>
      <Row
        style={{ alignItems: "baseline", justifyContent: "flex-end" }}
        gutter={[8, 8]}
      >
        <Col style={{ marginRight: "auto" }}>
          <Typography.Text>
            {intl.formatMessage(
              { defaultMessage: "Total games: {checked}/{total}" },
              {
                checked: state.checkedGamesCount,
                total: state.totalGamesCount,
              },
            )}
          </Typography.Text>
        </Col>
        <Col>
          <Button onClick={state.handleExport}>
            {intl.formatMessage({ defaultMessage: "Export" })}
          </Button>
        </Col>
        <Col>
          <Button onClick={state.handleImport}>
            {intl.formatMessage({ defaultMessage: "Import" })}
          </Button>
        </Col>
        {("clientId" in state.entity ||
          "agentId" in state.entity ||
          "hallId" in state.entity) && (
          <Col>
            <Button
              onClick={state.setToChildrenMutation.submit}
              loading={state.setToChildrenMutation.isPending}
              disabled={!state.mutation.isFulfilled}
            >
              {intl.formatMessage({ defaultMessage: "Set to children" })}
            </Button>
          </Col>
        )}
        <Col>
          <Button
            type="primary"
            onClick={state.mutation.submit}
            loading={state.mutation.isPending}
          >
            {intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
