import { ConfigProvider } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { RawIntlProvider } from "react-intl";

import { useGlobalStore } from "@/stores";

import { YupLocaleProvider } from "./yup-locale-provider";

export const IntlProvider: FC = observer(({ children }) => {
  const { intlService } = useGlobalStore();

  const direction = intlService.direction;

  return (
    <RawIntlProvider value={intlService.intl}>
      <ConfigProvider locale={intlService.antdLocale} direction={direction}>
        <YupLocaleProvider>{children}</YupLocaleProvider>
      </ConfigProvider>
    </RawIntlProvider>
  );
});
