import { yupResolver } from "@hookform/resolvers/yup";
import { MenuProps, notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { GameCategoryAPI, LanguageAPI, WebsiteAPI } from "@/api";
import { CategoryEdited, CloseEditCategoryModal } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import { useQueriesState } from "@/utilities/querie-state";

import { EditCategoryFormProps } from "./form";
import {
  EditCategoryFormSchema,
  useEditCategoryFormSchema,
} from "./form.schema";

export function useEditCategoryFormState({ id }: EditCategoryFormProps) {
  const intl = useIntl();

  const schema = useEditCategoryFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditCategoryFormSchema>({ resolver });

  const categoryQuery = useMemo(() => {
    const query = new Query(async () => {
      const category = await GameCategoryAPI.getById({ id });
      const parentCategory = category.parentId
        ? await GameCategoryAPI.getById({
            id: category.parentId,
          })
        : null;

      const { domainBlacklist, domainWhitelist, ...rest } = category;

      const values = schema.cast(
        {
          ...rest,
          domainIdsBlacklist: domainBlacklist.map((domain) => domain.id),
          domainIdsWhitelist: domainWhitelist.map((domain) => domain.id),
        },
        {
          assert: false,
          stripUnknown: true,
        },
      );
      values.tag ??= undefined;
      form.reset(values);

      return { category, parentCategory };
    });
    query.submit({});
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const category = categoryQuery.data?.category;
  const parentCategory = categoryQuery.data?.parentCategory;

  const languagesQuery = useMemo(() => {
    const query = new Query(async () => {
      return await LanguageAPI.getAll();
    });
    query.submit({});
    return query;
  }, []);

  const languages = languagesQuery.data?.data ?? [];

  const websitesQuery = useMemo(() => {
    const query = new Query(WebsiteAPI.getAll);
    query.submit();
    return query;
  }, []);

  const websites = useMemo(
    () => websitesQuery.data?.data ?? [],
    [websitesQuery.data?.data],
  );

  const queriesState = useQueriesState([
    categoryQuery,
    languagesQuery,
    websitesQuery,
  ]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(
      async ({ tag = null, ...data }: EditCategoryFormSchema) => {
        await GameCategoryAPI.update({ id, ...data, tag });

        notification.success({
          message: "",
          description: "Category updated successfully!",
        });

        eventBusService.publish(new CategoryEdited({}));
        eventBusService.publish(new CloseEditCategoryModal({}));
      },
    );
  }, [eventBusService, id]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  const names = form.watch("names") ?? {};

  const usedLanguages = Object.keys(names);
  const unusedLanguages = languages
    .map((language) => language.code)
    .filter((code) => !usedLanguages.includes(code));

  const addLanguageMenu: MenuProps = {
    onClick(item) {
      const names = form.watch("names", {});

      form.setValue("names", { ...names, [item.key]: "" });
    },
    items: unusedLanguages.map((code) => ({
      key: code,
      label: code.toUpperCase(),
    })),
  };

  const removeLanguage = (code: string) => {
    const names = form.watch("names", {});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [code]: _, ...rest } = names;

    form.setValue("names", rest);
  };

  return {
    intl,
    form,
    handleSubmit,
    mutation,
    category,
    parentCategory,
    languages,
    queriesState,
    usedLanguages,
    unusedLanguages,
    addLanguageMenu,
    removeLanguage,
    websites,
  };
}

export type EditCategoryFormState = ReturnType<typeof useEditCategoryFormState>;
