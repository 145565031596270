import { Row, Select } from "antd";
import DEFlag from "flag-icons/flags/4x3/de.svg";
import ESFlag from "flag-icons/flags/4x3/es.svg";
import FRFlag from "flag-icons/flags/4x3/fr.svg";
import ILFlag from "flag-icons/flags/4x3/il.svg";
import PTFlag from "flag-icons/flags/4x3/pt.svg";
import RUFlag from "flag-icons/flags/4x3/ru.svg";
import TRFlag from "flag-icons/flags/4x3/tr.svg";
import UAFlag from "flag-icons/flags/4x3/ua.svg";
import USFlag from "flag-icons/flags/4x3/us.svg";
import { observer } from "mobx-react-lite";
import { CSSProperties, FC } from "react";
import { useIntl } from "react-intl";

import { useDirection } from "@/hooks";
import { localeByCode, TLocaleCode } from "@/locales";
import { useGlobalStore } from "@/stores";

type Props = {
  style: CSSProperties;
};

type FlagComponent = typeof USFlag;

const flagComponentByCode: Record<TLocaleCode, FlagComponent> = {
  en: USFlag,
  es: ESFlag,
  fr: FRFlag,
  de: DEFlag,
  tr: TRFlag,
  pt: PTFlag,
  ru: RUFlag,
  uk: UAFlag,
  he: ILFlag,
};

const codes = Object.keys(localeByCode) as Array<keyof typeof localeByCode>;

export const LocaleSelector: FC<Props> = observer(({ style }) => {
  const intl = useIntl();
  const { intlService } = useGlobalStore();
  const dir = useDirection();
  return (
    <Select
      placeholder={intl.formatMessage({ defaultMessage: "Select language" })}
      value={intlService.locale}
      onChange={(locale) => intlService.setLocale(locale)}
      loading={
        intlService.messagesQuery.isPending ||
        intlService.antdLocaleQuery.isPending
      }
      style={style}
      showArrow
      size="small"
    >
      {codes.map((code) => {
        const FlagComponent = flagComponentByCode[code];
        const locale = localeByCode[code];
        return (
          <Select.Option key={locale.code} value={locale.code}>
            <Row align="middle">
              <FlagComponent
                height={12}
                style={
                  dir === "rtl" ? { marginLeft: "8px" } : { marginRight: "8px" }
                }
              />
              {locale.name}
            </Row>
          </Select.Option>
        );
      })}
    </Select>
  );
});
