import { Modal } from "antd";
import { observer } from "mobx-react-lite";

import { AddActionForm } from "./add-form";
import { AddActionModalState } from "./add-modal.state";

type Props = { state: AddActionModalState };

export const AddActionModalView = observer<Props>(({ state }) => {
  return (
    <Modal
      open={state.opened}
      onCancel={state.close}
      title="Add action"
      footer={null}
      destroyOnClose
      width={720}
    >
      {!!state.id && <AddActionForm categoryId={state.id} />}
    </Modal>
  );
});
