import { Input, InputRef } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { has } from "lodash-es";
import { ReactNode, Ref } from "react";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

import { useDirection } from "@/hooks";

interface Props<FV extends FieldValues> {
  inputRef?: Ref<InputRef>;
  form: UseFormReturn<FV>;
  path: Path<FV>;
  type?: string;
  placeholder?: string;
  align?: "left" | "center" | "right";
  suffix?: ReactNode;
  disabled?: boolean;
  size?: SizeType;
}

export const FormInput = <FV extends FieldValues>({
  inputRef,
  form,
  path,
  type,
  placeholder,
  align,
  suffix,
  disabled = false,
  size = "middle",
}: Props<FV>) => {
  const dir = useDirection();
  const currentAlign = align || dir === "rtl" ? "right" : "left";

  return (
    <Controller
      control={form.control}
      name={path}
      render={({ field }) => (
        <Input
          {...field}
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          style={{ textAlign: currentAlign }}
          status={has(form.formState.errors, path) ? "error" : ""}
          suffix={suffix}
          disabled={disabled}
          size={size}
        />
      )}
    />
  );
};
