import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Controller } from "react-hook-form";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import {
  FormInput,
  FormItem,
  FormSelect,
  FormSwitch,
} from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { ActionsTable } from "./actions/table";
import { DomainList } from "./domain-list";
import { EditCategoryFormState } from "./form.state";

type Props = {
  state: EditCategoryFormState;
};

export const EditCategoryFormView = observer<Props>(({ state }) => {
  const {
    form,
    handleSubmit,
    mutation,
    queriesState,
    category,
    parentCategory,

    usedLanguages,
    unusedLanguages,
    addLanguageMenu,
    removeLanguage,

    websites,
  } = state;

  if (queriesState.isLoading) {
    return <FormSpinner />;
  }

  if (queriesState.isRejected) {
    return queriesState.error;
  }

  const whiteListCount = form.watch("domainIdsWhitelist").length;
  const blackListCount = form.watch("domainIdsBlacklist").length;

  const whiteListLabel = (
    <div>
      {"Domain whitelist"}
      {Boolean(whiteListCount) && <span> ({whiteListCount})</span>}
    </div>
  );

  const blackListLabel = (
    <div>
      {"Domain blacklist"}
      {Boolean(blackListCount) && <span> ({blackListCount})</span>}
    </div>
  );

  return (
    <Space direction="vertical">
      {parentCategory && (
        <Form.Item label={"Parent category"}>
          <Typography.Text>{parentCategory?.names.en}</Typography.Text>
        </Form.Item>
      )}
      {/*<Tabs*/}
      {/*  destroyInactiveTabPane={true}*/}
      {/*  type="editable-card"*/}
      {/*  size="small"*/}
      {/*  hideAdd={true}*/}
      {/*  items={usedLanguages.map((code) => ({*/}
      {/*    key: code,*/}
      {/*    label: code.toUpperCase(),*/}
      {/*    closable: code !== "en",*/}
      {/*    children: (*/}
      <Form onFinish={handleSubmit}>
        <ErrorsFormatter queries={[mutation]} />
        <Tabs destroyInactiveTabPane={true}>
          <Tabs.TabPane tab="Languages" key="languages">
            <Form
              labelCol={{ span: 8 }}
              labelAlign="left"
              wrapperCol={{ span: 16 }}
              layout="horizontal"
            >
              {/*<FormItem*/}
              {/*  key={"en"}*/}
              {/*  form={form}*/}
              {/*  path={`names.em`}*/}
              {/*  label={"en".toUpperCase()}*/}
              {/*>*/}
              {/*  <FormInput*/}
              {/*    form={form}*/}
              {/*    path={`names.en`}*/}
              {/*    placeholder={"Enter name"}*/}
              {/*  />*/}
              {/*</FormItem>*/}
              {usedLanguages.map((code) => (
                <FormItem
                  key={code}
                  form={form}
                  path={`names.${code}`}
                  label={code.toUpperCase()}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 12,
                    }}
                  >
                    <FormInput
                      form={form}
                      path={`names.${code}`}
                      placeholder={"Enter name"}
                    />
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => removeLanguage(code)}
                      loading={mutation.isPending}
                      disabled={code === "en"}
                      title={"Delete Language"}
                      aria-label={"Delete message"}
                    />
                  </div>
                </FormItem>
              ))}
              <Row justify="end">
                {!!unusedLanguages.length && (
                  <Dropdown
                    menu={addLanguageMenu}
                    trigger={["click"]}
                    overlayClassName="language-dropdown"
                  >
                    <Button size="small">
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Common" key="common">
            <Form
              labelCol={{ span: 8 }}
              labelAlign="left"
              wrapperCol={{ span: 16 }}
              layout="horizontal"
            >
              <>
                <FormItem form={form} path="order" label={"Order"}>
                  <FormInput
                    form={form}
                    path="order"
                    placeholder={"Enter order"}
                  />
                </FormItem>
                {!parentCategory && (
                  <FormItem form={form} path="tag" label={"Tag"}>
                    <FormSelect
                      form={form}
                      path="tag"
                      placeholder={"Select tag"}
                      options={["header", "menu", "bottom"].map((value) => ({
                        value,
                        label: value,
                      }))}
                      allowClear
                    />
                  </FormItem>
                )}
                <FormItem form={form} path="isActive" label={"Active"}>
                  <FormSwitch form={form} path="isActive" />
                </FormItem>
              </>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Actions" key="actions">
            {!!category && (
              <ActionsTable
                categoryId={category.id}
                initialActions={category.actions}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={whiteListLabel} key="domain-whitelist">
            <Form
              labelCol={{ span: 8 }}
              labelAlign="left"
              wrapperCol={{ span: 16 }}
              layout="horizontal"
            >
              <FormItem
                form={form}
                path="domainIdsWhitelist"
                label={"Domain whitelist"}
              >
                <Controller
                  control={form.control}
                  name="domainIdsWhitelist"
                  render={({ field }) => (
                    <DomainList
                      list={field.value}
                      onChange={field.onChange}
                      websites={websites}
                    />
                  )}
                />
              </FormItem>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab={blackListLabel} key="domain-blacklist">
            <Form
              labelCol={{ span: 8 }}
              labelAlign="left"
              wrapperCol={{ span: 16 }}
              layout="horizontal"
            >
              <FormItem
                form={form}
                path="domainIdsBlacklist"
                label="Domain blackList"
              >
                <Controller
                  control={form.control}
                  name="domainIdsBlacklist"
                  render={({ field }) => (
                    <DomainList
                      list={field.value}
                      onChange={field.onChange}
                      websites={websites}
                    />
                  )}
                />
              </FormItem>
            </Form>
          </Tabs.TabPane>
        </Tabs>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
