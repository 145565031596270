import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

import { TWebsite } from "@/api";

interface Props {
  list: number[];
  onChange: (value: number[]) => void;
  websites: TWebsite[];
}

export const DomainList = observer(({ list, onChange, websites }: Props) => {
  const addedWebsites = useMemo(() => {
    return websites.filter((website) => list.includes(website.id));
  }, [websites, list]);

  const restWebsites = useMemo(() => {
    return websites.filter((website) => !list.includes(website.id));
  }, [websites, list]);

  const add = useCallback(
    (id: number) => onChange([...list, id]),
    [list, onChange],
  );

  function remove(id: number) {
    onChange(list.filter((item) => item !== id));
  }

  const dropdownMenu = useMemo(
    () => ({
      items: restWebsites.map((website) => ({
        key: website.id,
        label: website.domainName,
        onClick: () => add(website.id),
      })),
    }),
    [add, restWebsites],
  );

  return (
    <Space direction="vertical">
      {addedWebsites.map((website) => (
        <Row key={website.id} gutter={8}>
          <Col flex={1}>{website.domainName}</Col>
          <Col>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => remove(website.id)}
            />
          </Col>
        </Row>
      ))}
      {restWebsites.length > 0 && (
        <Row justify="end">
          <Dropdown
            destroyPopupOnHide={true}
            placement="bottomRight"
            menu={dropdownMenu}
            trigger={["click"]}
            dropdownRender={(originNode) => (
              <div style={{ maxHeight: "50dvh", overflow: "auto" }}>
                {originNode}
              </div>
            )}
          >
            <Button icon={<PlusOutlined />} />
          </Dropdown>
        </Row>
      )}
    </Space>
  );
});
