import { Button, Carousel, Col, Row, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import CheckboxList from "@/ui/_common_/checkbox-list";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { FormState } from "./form.state";

type Props = {
  state: FormState;
};

export const EditFormView: FC<Props> = observer(({ state }) => {
  const { intl, isLoading, isRejected, carouselRef } = state;

  if (isLoading) {
    return <FormSpinner />;
  }

  if (isRejected) {
    return (
      <ErrorsFormatter
        queries={[state.providersQuery, state.brandsQuery, state.gamesQuery]}
      />
    );
  }

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[state.mutation]} />
      <Carousel
        ref={carouselRef}
        infinite={false}
        slidesToShow={3}
        responsive={[{ breakpoint: 480, settings: { slidesToShow: 1 } }]}
        className="checkbox-list-carousel"
      >
        <div className="providers">
          <CheckboxList
            title={intl.formatMessage({ defaultMessage: "Providers" })}
            items={state.providerCheckboxList}
            onSelect={state.handleProviderSelect}
            onCheck={state.handleProviderCheck}
            onCheckAll={state.handleProviderCheckAll}
          />
        </div>
        <div className="brands">
          <CheckboxList
            title={intl.formatMessage({ defaultMessage: "Brands" })}
            items={state.brandCheckboxList}
            onSelect={state.handleBrandSelect}
            onCheck={state.handleBrandCheck}
            onCheckAll={state.handleBrandCheckAll}
          />
        </div>
        <div className="games">
          <CheckboxList
            title={intl.formatMessage({ defaultMessage: "Games" })}
            isSelectable={false}
            items={state.gameCheckboxList}
            onCheck={state.handleGameCheck}
            onCheckAll={state.handleGameCheckAll}
          />
        </div>
      </Carousel>
      <Row style={{ alignItems: "baseline" }} gutter={[8, 8]}>
        <Col style={{ marginRight: "auto" }}>
          <Typography.Text>
            {intl.formatMessage(
              { defaultMessage: "Total games: {checked}/{total}" },
              {
                checked: state.checkedGamesCount,
                total: state.totalGamesCount,
              },
            )}
          </Typography.Text>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={state.mutation.submit}
            loading={state.mutation.isPending}
          >
            {intl.formatMessage({ defaultMessage: "Save" })}
          </Button>
        </Col>
      </Row>
    </Space>
  );
});
