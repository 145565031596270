import { Space, Form, Switch, Row, Col, Button, Input } from "antd";
import { has } from "lodash-es";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import AreYouSure from "@/ui/_common_/are-you-sure";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormItem, FormSelect, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";
import Password from "@/ui/_common_/password";
import ReadOnlySelector from "@/ui/_common_/read-only-selector";
import { generatePassword } from "@/utilities";

import { useGeneralFormState } from "./GeneralFormState";

export interface Props {
  agentId: number;
  setAgentName(name: string): void;
}

export const GeneralForm = observer<Props>((props) => {
  const intl = useIntl();
  const { agentQuery, parentAgentQuery, submitQuery, form } =
    useGeneralFormState(props);

  useEffect(() => {
    reaction(
      () => submitQuery.isFulfilled,
      () => form.resetField("password"),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (agentQuery.isIdle || agentQuery.isPending || parentAgentQuery.isPending) {
    return <FormSpinner />;
  }

  if (agentQuery.isRejected || parentAgentQuery.isRejected) {
    return <ErrorsFormatter queries={[agentQuery, parentAgentQuery]} />;
  }

  const agent = agentQuery.data!;

  return (
    <Space direction="vertical">
      <ErrorsFormatter queries={[submitQuery]} />
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={form.handleSubmit(submitQuery.submit)}
      >
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Parent agent" })}
        >
          <ReadOnlySelector
            placeholder={intl.formatMessage({
              defaultMessage: "Select parent agent",
            })}
            query={parentAgentQuery}
            id={agent.parentAgentId ?? undefined}
            labelKey="name"
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Name" })}>
          <Input value={agent.name} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Admin username" })}
        >
          <Input value={agent.login} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Admin password" })}
          validateStatus={has(form.formState.errors, "password") ? "error" : ""}
          help={form.formState.errors.password?.message}
        >
          <Controller
            control={form.control}
            name="password"
            render={({ field }) => (
              <Password
                {...field}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter password",
                })}
                onCopy={() => {
                  const password = form.getValues().password ?? "";
                  if (password) {
                    navigator.clipboard.writeText(password);
                  }
                }}
                onGenerate={() => form.setValue("password", generatePassword())}
                // disable autocomplete
                readOnly
                onFocus={(e) => e.target.removeAttribute("readonly")}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Unlimited balance" })}
          validateStatus={
            has(form.formState.errors, "isBalanceUnlimited") ? "error" : ""
          }
          help={form.formState.errors.isBalanceUnlimited?.message}
        >
          <Controller
            control={form.control}
            name="isBalanceUnlimited"
            render={({ field }) => (
              <Switch checked={!!field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: "Blocked" })}
          validateStatus={
            has(form.formState.errors, "isBlocked") ? "error" : ""
          }
          help={form.formState.errors.isBlocked?.message}
        >
          <Controller
            control={form.control}
            name="isBlocked"
            render={({ field }) => (
              <AreYouSure
                onYes={() => form.setValue("isBlocked", true)}
                onNo={() => form.setValue("isBlocked", false)}
                disabled={!!field.value}
              >
                <Switch checked={!!field.value} onChange={field.onChange} />
              </AreYouSure>
            )}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ defaultMessage: "Currency" })}>
          <Input value={agent.currency ?? "—"} disabled />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            defaultMessage: "Can create subagents",
          })}
          validateStatus={
            has(form.formState.errors, "canHaveChildren") ? "error" : ""
          }
          help={form.formState.errors.canHaveChildren?.message}
        >
          <Controller
            control={form.control}
            name="canHaveChildren"
            render={({ field }) => (
              <Switch checked={!!field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>
        <FormItem
          form={form}
          path="configuredByParent"
          label={intl.formatMessage({ defaultMessage: "Configured by parent" })}
        >
          <FormSwitch form={form} path="configuredByParent" />
        </FormItem>
        <FormItem
          form={form}
          path="kycType"
          label={intl.formatMessage({
            defaultMessage: "KYC Type",
          })}
        >
          <FormSelect
            form={form}
            path="kycType"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "No need" }),
                value: null,
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Manual",
                }),
                value: "manual",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Provider",
                }),
                value: "provider",
              },
            ]}
            placeholder={intl.formatMessage({
              defaultMessage: "Select KYC Type",
            })}
          />
        </FormItem>
        <FormItem
          form={form}
          path="gameDisplayType"
          label={intl.formatMessage({
            defaultMessage: "Shop games for player",
          })}
        >
          <FormSelect
            form={form}
            path="gameDisplayType"
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Always" }),
                value: "always",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "KYC confirmed",
                }),
                value: "kyc_confirmed",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "KYC confirmed or Deposit",
                }),
                value: "kyc_confirmed_or_deposit",
              },
            ]}
            placeholder={intl.formatMessage({
              defaultMessage: "Shop games for player",
            })}
          />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitQuery.isPending}
            >
              {intl.formatMessage({ defaultMessage: "Save" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
